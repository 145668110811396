import featureFlags from "./FeatureFlags";
import {AuthUserRole, AuthUser} from "../auth/authTypes";
import {AppConfig} from "../ts/app/AppConfig";

/**
 * List overview of Portal features
 */
export enum PortalFeatures {
	accountSettings = "accountSettings",
	addMeetingNote = "addMeetingNote",
	autoTimeTracking = "autoTimeTracking",
	changeLanguage = "changeLanguage",
	changeOnlineStatus = "changeOnlineStatus",
	chat = "chat",
	clientsBirthday = "clientsBirthday",
	dataTab = "dataTab",
	editBio = "editUserBio",
	editAvatar = "editUserAvatar",
	editProfileData = "editUserData",
	emdr = "EMDR",
	dashboard = "dashboard",
	disconnectContact = "disconnectContact",
	fileSharing = "fileSharing",
	freshdesk = "freshdesk",
	groupCall = "conferenceCall",
	inactiveClients = "inactiveClients",
	incomingInvitation = "incomingInvitation",
	interventionPage = "interventionPage",
	inviteParticipantsToCall = "inviteParticipantsToCall",
	library = "library",
	needDrivenDashboard = "needDrivenDashboard",
	nicedayBlogSearch = "nicedayBlogSearch",
	notificationBell = "notificationBell",
	organizationSelector = "organizationSelector",
	privateNotes = "privateNotes",
	psychoEducationV2 = "psychoEducationV2",
	rateCallQuality = "rateCallQuality",
	serviceStatus = "serviceStatus",
	therapistEmailVerification = "therapistEmailVerification",
	timeTracking = "timeTracking",
	twoFactorAuthentication = "twoFactorAuthentication",
	planActivityForm = "planActivityForm",
	treatmentStatus = "treatmentStatus",
	viewClientPage = "viewClientPage",
	viewTherapistPage = "viewTherapistPage",
	viewProfile = "viewUserProfile",
	videoCall = "videoCall",
	warningBar = "warningBar",
	calendar = "calendar",
	conditionalEmdr = "conditionalEmdr",
	toolbar = "toolbar",
	dedicatedNotePage = "dedicatedNotePage",
	productFruits = "productFruits",
	qrCode = "qrCode",
	behaviorExperiment = "behaviorExperiment",
	topNavButtons = "topNavButtons",
	groupTherapy = "groupTherapy",

	// Per https://github.com/senseobservationsystems/web-getgoalie/issues/4911,
	// for solo therapists, we have to prevent portal from fetching
	// other therapists.
	fetchingOtherTherapistsContact = "fetchingOtherTherapistsContact",
	fetchingTherapistDiffOrgs = "fetchingTherapistDiffOrgs",

	/**
	 * The `networkTab` and `treatmentSupervisors` below are two new flag
	 * to help portal disable both of those features for International-Solo therapists.
	 */
	networkTab = "networkTab",
	treatmentSupervisors = "treatmentSupervisors",

	/**
	 * This is controlling the access to the caseload page,
	 * where we list all the clients that either connected to the therapist,
	 * or is part of ongoing treatment of that client.
	 */
	therapistCaseload = "therapistCaseload",

	xaiProject = "xaiProject",

	/**
	 * Portal clean up v1 new main menu with a dropdown that have these sub-menus:
	 * - Library
	 * - Niceday Blog
	 * - Knowledge Base
	 */
	resources = "resources",

	ttExport = "ttExport",

	/**
	 * For the `/org` page
	 */
	adminPage = "adminPage",
	adminMembersPage = "adminMembersPage",
}

/**
 * Feature will be always available on feature flag mapping check,
 */
const noFeatureFlag = () => true;

/**
 * Feature flag mapped to Portal features.
 * This object contains information of whether PortalFeatures has feature flag enabled or not.
 */
const featureFlagMap: {[key in PortalFeatures]: () => boolean} = {
	[PortalFeatures.accountSettings]: () => featureFlags.accountSettings,
	[PortalFeatures.addMeetingNote]: noFeatureFlag,
	[PortalFeatures.autoTimeTracking]: () => featureFlags.autoTimeTracking,
	[PortalFeatures.changeLanguage]: noFeatureFlag,
	[PortalFeatures.changeOnlineStatus]: () => featureFlags.chatPresenceTherapist,
	[PortalFeatures.chat]: () => featureFlags.chat,
	[PortalFeatures.clientsBirthday]: () => featureFlags.clientsBirthday,
	[PortalFeatures.dataTab]: noFeatureFlag,
	[PortalFeatures.editBio]: noFeatureFlag,
	[PortalFeatures.editAvatar]: noFeatureFlag,
	[PortalFeatures.editProfileData]: noFeatureFlag,
	[PortalFeatures.therapistEmailVerification]: () => featureFlags.therapistEmailVerification,
	[PortalFeatures.emdr]: () => featureFlags.emdr,
	[PortalFeatures.dashboard]: noFeatureFlag,
	[PortalFeatures.disconnectContact]: noFeatureFlag,
	[PortalFeatures.fileSharing]: () => featureFlags.fileSharing,
	[PortalFeatures.freshdesk]: () => featureFlags.freshdesk,
	[PortalFeatures.groupCall]: () => featureFlags.twilioConferenceCall,
	[PortalFeatures.inactiveClients]: () => featureFlags.inactiveClient,
	[PortalFeatures.incomingInvitation]: noFeatureFlag,
	[PortalFeatures.interventionPage]: () => featureFlags.interventionPage,
	[PortalFeatures.inviteParticipantsToCall]: noFeatureFlag,
	[PortalFeatures.library]: noFeatureFlag,
	[PortalFeatures.needDrivenDashboard]: () => featureFlags.clientsFeeds,
	[PortalFeatures.nicedayBlogSearch]: () => featureFlags.nicedayBlogSearch,
	[PortalFeatures.notificationBell]: noFeatureFlag,
	[PortalFeatures.organizationSelector]: noFeatureFlag,
	[PortalFeatures.privateNotes]: () => featureFlags.privateNotes,
	[PortalFeatures.psychoEducationV2]: () => featureFlags.psychoEducationV2,
	[PortalFeatures.rateCallQuality]: noFeatureFlag,
	[PortalFeatures.serviceStatus]: noFeatureFlag,
	[PortalFeatures.timeTracking]: () => featureFlags.timeTracking,
	[PortalFeatures.twoFactorAuthentication]: () => featureFlags.twoFactorAuthentication,
	[PortalFeatures.planActivityForm]: () => featureFlags.planActivityForm,
	[PortalFeatures.treatmentStatus]: () => featureFlags.treatmentStatus,
	[PortalFeatures.videoCall]: () => featureFlags.twilioCall,
	[PortalFeatures.viewClientPage]: noFeatureFlag,
	[PortalFeatures.viewTherapistPage]: noFeatureFlag,
	[PortalFeatures.viewProfile]: noFeatureFlag,
	[PortalFeatures.warningBar]: () => featureFlags.warningBar,
	[PortalFeatures.calendar]: () => featureFlags.calendar,
	[PortalFeatures.conditionalEmdr]: () => featureFlags.conditionalEmdr,
	[PortalFeatures.toolbar]: () => featureFlags.toolbarDataPage,
	[PortalFeatures.dedicatedNotePage]: () => featureFlags.dedicatedNotesPage,
	[PortalFeatures.fetchingOtherTherapistsContact]: () => !featureFlags.soloTherapistBehavior,
	[PortalFeatures.networkTab]: () => featureFlags.clientNetwork && !featureFlags.soloTherapistBehavior,
	[PortalFeatures.treatmentSupervisors]: () => !featureFlags.soloTherapistBehavior,
	[PortalFeatures.fetchingTherapistDiffOrgs]: noFeatureFlag,
	[PortalFeatures.productFruits]: () => featureFlags.productFruits,
	[PortalFeatures.qrCode]: () => featureFlags.therapistQrCode,
	[PortalFeatures.behaviorExperiment]: () => featureFlags.behaviorExperiment,
	[PortalFeatures.topNavButtons]: () => featureFlags.topNavButtons,
	[PortalFeatures.therapistCaseload]: () => featureFlags.therapistCaseload,
	[PortalFeatures.xaiProject]: () => featureFlags.xaiProject,
	[PortalFeatures.resources]: noFeatureFlag,
	[PortalFeatures.ttExport]: () => featureFlags.timeTrackingExport,
	[PortalFeatures.adminPage]: () => featureFlags.adminPage,
	[PortalFeatures.adminMembersPage]: () => featureFlags.adminPageMembers,
	[PortalFeatures.groupTherapy]: () => featureFlags.groupTherapy,
};

const allFeatures = Object.keys(PortalFeatures).map((key) => PortalFeatures[key]) as PortalFeatures[];

/** List of restricted features for therapists who are still waiting to be accepted into an organization */
const pendingTherapistRestrictedFeatures = [
	PortalFeatures.timeTracking,
	PortalFeatures.autoTimeTracking,
	PortalFeatures.calendar,
	PortalFeatures.fetchingOtherTherapistsContact,
	PortalFeatures.qrCode,
	PortalFeatures.therapistCaseload,
	PortalFeatures.productFruits,
];

/** List of features accessible for therapists who are still waiting to be accepted into an organization */
const pendingTherapistFeatures = allFeatures.filter((feature) => !pendingTherapistRestrictedFeatures.includes(feature));

type AuthUserOrg = AuthUser["organization"];
type UserAccessByRoleFn = (org: AuthUserOrg) => PortalFeatures[];

/**
 * This function will calculate which features should be available
 * given the information about the user's organization.
 */
function getAvailableFeatureForTherapist(userOrg: AuthUserOrg) {
	if (!userOrg?.isAccepted) {
		return pendingTherapistFeatures;
	}

	const features = allFeatures.slice();
	const nonCustomerOrgIds = [AppConfig.NDT_ORG, AppConfig.SUPPORT_ORG];

	if (!nonCustomerOrgIds.includes(userOrg?.id)) {
		// Don't fetch therapists from different organizations if it's from customer organizations
		const fetchAllTherapistsIdx = features.findIndex(
			(feature) => feature === PortalFeatures.fetchingTherapistDiffOrgs,
		);
		features.splice(fetchAllTherapistsIdx, 1);
	}

	if (AppConfig.SOLO_THERAPIST_ORG === userOrg?.id) {
		// Prevent fetching the contacts of other therapists if the user
		// comes from International-Solo therapists.
		const featureIdx = features.findIndex((feature) => feature === PortalFeatures.fetchingOtherTherapistsContact);
		features.splice(featureIdx, 1);

		// Prevent showing the network tab for International-Solo therapists.
		const networkTabIdx = features.findIndex((feature) => feature === PortalFeatures.networkTab);
		features.splice(networkTabIdx, 1);

		// Prevent showing the treatment supervisors in treatment form for International-Solo therapists.
		const treatmentSupervisorsIdx = features.findIndex(
			(feature) => feature === PortalFeatures.treatmentSupervisors,
		);
		features.splice(treatmentSupervisorsIdx, 1);
	}

	return features;
}

/**
 * Portal features mapped to AuthUserRole.
 * This object contains information of which features a User can acccess.
 */
export const userAccessByRole: {[key in AuthUserRole]: UserAccessByRoleFn} = {
	[AuthUserRole.CLIENT]: () => [
		PortalFeatures.changeLanguage,
		PortalFeatures.chat,
		PortalFeatures.fileSharing,
		PortalFeatures.rateCallQuality,
		PortalFeatures.serviceStatus,
		PortalFeatures.videoCall,
		PortalFeatures.viewTherapistPage,
	],
	[AuthUserRole.THERAPIST]: getAvailableFeatureForTherapist,
	[AuthUserRole.RESEARCHER]: () => pendingTherapistFeatures,
};

const isFeatureEnabled = (feature: PortalFeatures) => featureFlagMap[feature]?.() || false;

const checkIfRoleCanAccessFeature = (feature: PortalFeatures, role: AuthUserRole, org: AuthUserOrg) => {
	const featureAccess = userAccessByRole[role](org);
	if (!featureAccess || featureAccess.length === 0) {
		return false;
	}
	return featureAccess.indexOf(feature) > -1;
};

/**
 * Returns true if user role can access given feature
 *
 * @param feature
 * @param role
 * @param isAcceptedToOrg
 */
const checkFeatureFlagByRoleAndOrg = (feature: PortalFeatures, role: AuthUserRole, orgInfo: AuthUserOrg) => {
	return isFeatureEnabled(feature) && checkIfRoleCanAccessFeature(feature, role, orgInfo);
};

export const checkAuthUserAccess = (authUser: AuthUser) => {
	return (feature: PortalFeatures) => {
		return checkFeatureFlagByRoleAndOrg(feature, authUser.role, authUser.organization);
	};
};
