/**
 * List of available FeatureFlag keys from Backend
 */
export enum ClientFeatureFlagKeys {
	chat = "chat",
	clientNetwork = "client_network",
	chatPresenceClient = "chat_presence_client",
	chatPresenceTherapist = "chat_presence_therapist",
	chatboxDefaultOpen = "chatbox_default_open",
	stepCount = "step_count",
	dataLastSync = "data_last_sync",
	behaviorExperiment = "behavior_experiment",
	clientsFeeds = "clients_feeds",
	clientsBirthday = "clients_birthday",
	analytics = "analytics",
	backgroundNotifications = "background_notifications",
	therapySession = "therapy_session",
	deletePlannedEvents = "delete_planned_events",
	loggerNotification = "logger_notification",
	editEvents = "edit_events",
	chatReplyMessage = "chat_reply_message",
	recurringPlannedEvents = "recurring_planned_events",
	recurringPlannedEventsCreation = "recurring_planned_events_creati",
	questionnaires = "questionnaires",
	twilioCall = "twilio_call",
	thoughtRecord = "thought_record",
	customTracker = "custom_tracker",
	customTrackerPart2 = "custom_tracker_part2",
	conferenceCall = "conference_call",
	twilioConferenceCall = "twilio_conference_call",
	emdr = "emdr",
	inactiveClient = "inactive_client",
	customTrackerReminder = "custom_tracker_reminder",
	freshdesk = "freshdesk",
	feedRegistrationDetail = "feed_registration_detail",
	twilioNetworkQuality = "twilio_network_quality",
	treatmentStatus = "treatment_status",
	nicedayBlogSearch = "niceday_blog_search",
	privateNotes = "private_notes",
	unverifiedDashboard = "unverified_dashboard",
	customClientDetails = "custom_client_details",
	autoTimeTracking = "auto_time_tracking",
	timeTracking = "time_tracking",
	planActivityForm = "plan_activity_form",
	warningBar = "warning_bar",
	callEffects = "call_effects",
	fileSharing = "file_sharing",
	therapistJobSpecialization = "therapist_job_specialization",
	accountSettings = "account_settings",
	twoFactorAuthentication = "two_factor_authentication",
	portalForTablet = "portal_for_tablet",
	therapistEmailVerification = "therapist_email_verification",
	calendar = "calendar",
	conditionalEmdr = "conditional_emdr",
	outgoingCallType = "outgoing_call_type",
	toolbarDataPage = "toolbar_data_page",
	dedicatedNotesPage = "dedicated_notes_page",
	therapistQrCode = "therapist_qr_code",
	psychoEducationV2 = "psycho_education_v2",
	productFruits = "product_fruits",
	interventionPage = "intervention_page",
	interventionNote = "intervention_note",
	interventionDiary = "intervention_diary",
	interventionTreatmentGoal = "intervention_treatment_goal",
	interventionCustomTracker = "intervention_custom_tracker",
	interventionPsychoeducation = "intervention_psychoeducation",
	interventionBehaviorExperiment = "intervention_behavior_expr",
	interventionSession = "intervention_session",
	interventionAssessment = "intervention_assessment",
	autoTreatmentForTT = "auto_treatment_for_tt",
	postCallDialogs = "post_call_dialogs",
	unrestrictedSessionAndNote = "unrestricted_session_and_note",
	topNavButtons = "top_nav_buttons",
	customTrackerDataPage = "custom_tracker_data_page",
	callMediaStats = "call_media_stats",
	callMediaTrackStats = "call_media_track_stats",
	therapistCaseload = "therapist_caseload",
	therapistOrgContacts = "therapist_org_contacts",
	disableExerciseFeelings = "disable_exercise_feelings",
	callWithH264 = "h264_web",
	xaiProject = "xai_project",
	referralId = "referral_id",
	trackersV3 = "trackers_v3",
	bufferedQualityIndicator = "buffered_quality_indicator",
	taskRegistration = "task_registration",
	interventionTaskRegistration = "intervention_task_registration",
	soloTherapistBehavior = "solo_therapist_behavior",
	assessment = "assessment",
	callMediaRegionGll = "call_region_gll",
	hideAssessmentTitle = "hide_assessment_title",
	portalCleanupV1 = "portal_cleanup_v1",
	trackersV4 = "trackers_v4",
	callWarning = "call_warning",
	graphV2 = "graph_v2",
	graphV2AllTime = "graph_v2_all_time",
	graphV2Overview = "graph_v2_overview",
	tt_export = "tt_export",
	redesignedAuthPages = "redesigned_auth_pages",
	dropdownMenuLanguages = "dropdown_menu_languages",
	adminPage = "admin_page",
	adminMembersPage = "admin_page__members",
	callExtendedReconnect = "call_extended_reconnect",
	presetsModule = "presets_module",
	freshchat = "portal_freshchat",
	sessionMeetInfo = "session_meet_info",
	clientProfilePageCleanup = "client_profile__cleanup",
	supportOrgTherapists = "support_org_therapists",
	groupTherapy = "group_therapy",
}
